import React from 'react'

function Landing() {
  return (
    <div style={{textAlign: 'center'}}>
      <h1>Emaily</h1>
    </div>
  )
}

export default Landing